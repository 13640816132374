import React, { useEffect, useState } from 'react';
import { array, bool } from 'prop-types';
import AddCard from 'components/cards/add';
import useStyles from 'apputil/view-styles';
import SkeletonCard from 'components/cards/skeleton';
import { Grid } from '@material-ui/core';
import loyaltyCardFactory from './loyalty-card-factory';

const LoyaltyCardsView = ({ passes = [], canAdd = false }) => {
  const styles = useStyles();
  const [builtPasses, setBuiltPasses] = useState([]);
  const [loading, setLoading] = useState(true);

  const fakePasses = [];
  for (let i = 0; i < passes.length; i += 1) {
    fakePasses.push(<SkeletonCard/>);
  }

  useEffect(() => {
    setBuiltPasses(passes.map(
      (pass, index) => loyaltyCardFactory(pass),
    ));
    setLoading(false);
    // eslint-disable-next-line
  }, [passes]);

  return (
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
        {canAdd && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AddCard />
          </Grid>
        )}
        {loading ? (
          <>
          {fakePasses.map((fake, index) => (
            <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
            {fake}
            </Grid>
          ))}
          </>
        ) : (
          <>
          {builtPasses.map((pass, index) => (
            <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
            {pass}
            </Grid>
          ))}
          </>
        )}
    </Grid>
  </div>
  );
};

LoyaltyCardsView.propTypes = {
  passes: array.isRequired,
  canAdd: bool,
};

export default LoyaltyCardsView;
