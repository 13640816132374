import React from 'react';
import { DateTime } from 'luxon';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import { CARD_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'components/cards/skeleton';

import { faIdCard } from '@fortawesome/pro-solid-svg-icons';
import { CardMedia } from '@material-ui/core';

import BaseCard from 'components/cards/base-card';
import useStyles from './styles';

const ActiveLoyaltyCard = ({
  image,
  title,
  description,
  passId,
}) => {
  const styles = useStyles();
  const startDate = DateTime.local();
  const created = startDate.toSeconds();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${CARD_ROUTE}?passId=${passId}`)}
          title={title}
          description={description}
          buttonMessage="Open Card"
          imageChild={
              <CardMedia
                className={styles.image}
                image={`${image}?${created}`}
                title={title}
              />
            }
              iconChild={
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faIdCard} />
                </span>
              }
          fallback={<Skeleton/>}
          />;
};

ActiveLoyaltyCard.propTypes = {
  passId: string.isRequired,
  image: string,
  title: string,
  description: string,
};

ActiveLoyaltyCard.defaultProps = {
  title: '',
  description: '',
  image: 'none',
};

export default ActiveLoyaltyCard;
