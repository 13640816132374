import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { CREATE_MEDIA_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'components/cards/skeleton';

import { faEdit, faIdCard } from '@fortawesome/pro-solid-svg-icons';

import { CardMedia } from '@material-ui/core';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const NotCompleteLoyaltyCard = ({
  image,
  title,
  passId,
}) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${CREATE_MEDIA_ROUTE}?passId=${passId}`)}
          title={title}
          description={'This card is not complete. Please finish this card to use it as a loyalty card.'}
          buttonMessage="Edit Card"
          imageChild={
            image && image !== 'none'
              ? (
                <CardMedia
                  className={styles.image}
                  image={image}
                  title={title}/>
              ) : (
                <div className={styles.iconContainer}>
                  <FontAwesomeIcon icon={faEdit} size="8x" className={styles.largeIcon}/>
                </div>
              )
            }
          iconChild={
            <FontAwesomeIcon
                icon={image && image !== 'none' ? faIdCard : faEdit}
                className={styles.icon}/>
              }
          fallback={<Skeleton/>}
          />;
};

NotCompleteLoyaltyCard.propTypes = {
  passId: string.isRequired,
  image: string,
  title: string,
};

NotCompleteLoyaltyCard.defaultProps = {
  title: '',
  image: 'none',
};

export default NotCompleteLoyaltyCard;
