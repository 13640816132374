import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import Skeleton from 'components/cards/skeleton';

import { CREATE_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const AddCard = () => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(CREATE_ROUTE)}
          title="Add"
          description="Create a new loyalty card"
          buttonMessage="Add"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faLayerPlus} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faLayerPlus}/>
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

export default AddCard;
