import theme from 'theme';
import basecard from 'apputil/card-styles';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  ...basecard,
  image: {
    paddingTop: '30%', // 16:9
    paddingBottom: '25%', // 16:9
    marginTop: theme.spacing(2),
    marginLeft: '20%',
    marginRight: '20%',
    borderRadius: '10px',
  },
});
