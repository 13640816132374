import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { CARD_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'components/cards/skeleton';

import { faClipboardListCheck } from '@fortawesome/pro-solid-svg-icons';
import { CardMedia } from '@material-ui/core';
import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const CompleteLoyaltyCard = ({
  image,
  title,
  passId,
}) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${CARD_ROUTE}?passId=${passId}`)}
          title={title}
          description={
            'Please enable this card to use it as part of your loyalty scheme. You can update the text, colours and image anytime.'
          }
          buttonMessage="Open Card"
          imageChild={
            <CardMedia
              className={styles.image}
              image={image}
              title={title}
            />
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faClipboardListCheck} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

CompleteLoyaltyCard.propTypes = {
  passId: string.isRequired,
  image: string,
  title: string,
};

CompleteLoyaltyCard.defaultProps = {
  title: '',
  image: 'none',
};

export default CompleteLoyaltyCard;
