import React from 'react';
import ActiveLoyaltyCard from 'components/cards/loyalty-cards/active-loyalty';
import CompleteLoyaltyCard from 'components/cards/loyalty-cards/complete-loyalty';
import NotCompleteLoyaltyCard from 'components/cards/loyalty-cards/not-complete-loyalty';

const PASS_NOT_COMPLETE = 'not-complete';
const PASS_COMPLETE = 'complete';
const PASS_ACTIVE = 'active';

const loyaltyCardFactory = (pass) => {
  const {
    passId,
    name,
    image,
    description,
    state,
  } = pass;

  if (state === PASS_ACTIVE) {
    return <ActiveLoyaltyCard
              passId={passId}
              image={image}
              title={name}
              description={description}
              list/>;
  }

  if (state === PASS_NOT_COMPLETE) {
    return <NotCompleteLoyaltyCard
              passId={passId}
              image={image}
              title={name}
              list/>;
  }

  if (state === PASS_COMPLETE) {
    return <CompleteLoyaltyCard
              passId={passId}
              image={image}
              title={name}
              list/>;
  }

  return null;
};

export default loyaltyCardFactory;
